import styled from 'styled-components';
import { Typography } from 'antd';
import { TitleProps } from '@/shared/props';
import { StyledRow } from '@/shared/styles';
import { isMobile } from 'react-device-detect';

const { Title } = Typography;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${() => (isMobile ? `margin: 16px;` : `padding-top: 50px;`)}
`;

export const StyledOrangeGradTitle = styled(Title)<TitleProps>`
  background: ${(props) => props.theme.textGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: fit-content;

  font-weight: 600;
  font-size: ${(props) => props.$size ?? '32'}px !important;
  line-height: 48px;
  align-self: ${(props) => (props.$flex ? `flex-${props.$flex}` : `center`)} !important;
`;

export const StyledWhiteTitle = styled(Title)<TitleProps>`
  color: ${(props) => props.theme.textColor} !important;
  margin-top: 0px !important;
  font-size: ${(props) => (isMobile ? '16' : props.$size ?? '32')}px !important;
  margin-bottom: 0px;
  text-align: center;
  ${(props) => props.$width && `width: ${props.$width};`}
  ${() => isMobile && `font-weight: 400 !important;`}
`;

export const StyledToggleGroup = styled.div`
  color: ${(props) => props.theme.textColor};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  gap: 16px;
`;

export const StyledPricingMethod = styled(StyledRow)`
  width: fit-content;
  height: ${() => (isMobile ? '75' : '120')}px;
  justify-content: flex-end;
  position: relative;
  align-items: flex-end;
`;

export const StyledPricingText = styled.span`
  padding-bottom: 3px;
`;

export const StyledDiscountIcon = styled.div`
  position: absolute;
  left: 100%;
`;
