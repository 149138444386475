import { Icon } from '@/shared/components/Icon';
import {
  adminsIcon,
  billingIcon,
  dealsIcon,
  pricingIcon,
  projectsIcon,
  settingsIcon,
  statsIcon,
  usersIcon,
  vendorsIcon,
} from '@/assets/images/icons';
import { PAGES, USER_ROLE, USER_TYPE } from '@/shared/consts';
import { ReactNode, useEffect, useState } from 'react';
import { t } from 'i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { MenuProps } from 'antd';
import { PinkGradientLink } from '@/shared/components/Link/PinkGradientLink';
import { ArrowDown } from '@/shared/components/ArrowDown/ArrowDown';
import { U1Link } from '@/shared/components/Link/U1Link';
import { PinkGradientRouterLink } from '@/shared/components/Link/PinkGradientRouterLink';
import { MenuItemType } from './MenuItems.consts';
import MenuItem from 'antd/lib/menu/MenuItem';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { isMobile } from 'react-device-detect';
import { useUserStore } from '@/stores/UserStore';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { SUB_STATUS } from '@/stores/Stores.consts';

export type MenuItemProps = {
  key: string;
  text: string;
  href?: string;
  children?: MenuItemProps[];
  icon?: ReactNode;
};

export const useMenuItems = () => {
  const userDetails = useUserStore();
  const subscription = useSubscriptionStore();
  const { getLangRoute } = useU1Navigation();

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItemType => {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItemType;
  };

  const getFinalItems = (menuItemProps: MenuItemProps[]): ItemType[] => {
    const menuItems: MenuProps['items'] = [];
    menuItemProps.forEach((menuItem) =>
      menuItems.push(getItem(menuItem.text, menuItem.key, menuItem.icon))
    );
    return menuItems;
  };

  const adminMenuItems = (): MenuItemProps[] => [
    {
      key: 'docs',
      text: t('side_menu.docs'),
      href: PAGES.AFTER_LOGIN.ADMIN_DOCS,
      icon: <Icon $src={projectsIcon} />,
    },
    {
      key: 'admins',
      text: t('side_menu.admins'),
      href: PAGES.AFTER_LOGIN.ADMINS,
      icon: <Icon $src={adminsIcon} />,
    },
    {
      key: 'vendors',
      text: t('side_menu.vendors'),
      href: PAGES.AFTER_LOGIN.VENDORS,
      icon: <Icon $src={vendorsIcon} />,
    },
  ];

  const vendorMenuItems = (): MenuItemProps[] => [
    {
      key: 'deals',
      text: t('side_menu.deals'),
      href: PAGES.AFTER_LOGIN.DEALS,
      icon: <Icon $src={dealsIcon} />,
    },
    {
      key: 'stats',
      text: t('side_menu.stats'),
      href: PAGES.AFTER_LOGIN.STATS,
      icon: <Icon $src={statsIcon} />,
    },
    {
      key: 'settings',
      text: t('side_menu.settings'),
      href: PAGES.AFTER_LOGIN.SETTINGS,
      icon: <Icon $src={settingsIcon} />,
    },
  ];

  const clientMenuItems = (): MenuItemProps[] => {
    return [
      {
        key: 'projects',
        text: t('side_menu.projects'),
        href: PAGES.AFTER_LOGIN.PROJECTS,
        icon: <Icon $src={projectsIcon} />,
      },
      {
        key: 'users',
        text: t('side_menu.users'),
        href: PAGES.AFTER_LOGIN.USERS,
        icon: <Icon $src={usersIcon} />,
      },
      {
        key: 'billing',
        text: t('side_menu.billing'),
        href: PAGES.AFTER_LOGIN.BILLING,
        icon: <Icon $src={billingIcon} />,
      },
      {
        key: 'pricing',
        text: t('side_menu.pricing'),
        href: PAGES.AFTER_LOGIN.PRICING,
        icon: <Icon $src={pricingIcon} data-testid="pricingIcon" />,
      },
    ];
  };

  const clientMenu = (): Record<string, MenuItemProps[]> => ({
    basic: [clientMenuItems()[3]],
    user: [clientMenuItems()[0]],
    admin: [clientMenuItems()[0], clientMenuItems()[1], clientMenuItems()[2]],
    freemium: [
      clientMenuItems()[0],
      clientMenuItems()[1],
      clientMenuItems()[2],
      clientMenuItems()[3],
    ],
    disabled: [clientMenuItems()[0], clientMenuItems()[2], clientMenuItems()[3]],
  });

  const adminMenu = (): MenuItemProps[] => [
    adminMenuItems()[0],
    adminMenuItems()[1],
    adminMenuItems()[2],
  ];

  const vendorMenu = (): MenuItemProps[] => [
    vendorMenuItems()[0],
    vendorMenuItems()[1],
    vendorMenuItems()[2],
  ];

  const getClientMenuItems = (type: string): ItemType[] => {
    if (isMobile) return clientMenu()[type];
    return getFinalItems(clientMenu()[type]);
  };

  const [menuItems, setItems] = useState(getClientMenuItems('basic'));

  const setClientMenuItems = () => {
    if (subscription.subscriptionStatus === SUB_STATUS.HAS_SUBSCRIPTION) {
      if (userDetails.role === USER_ROLE.ADMIN) {
        if (subscription.isFreemium) {
          setItems(getClientMenuItems('freemium'));
        } else {
          setItems(getClientMenuItems('admin'));
        }
      }
      if (userDetails.role === USER_ROLE.USER) setItems(getClientMenuItems('user'));
    } else if (
      subscription.subscriptionStatus === SUB_STATUS.SUBSCRIPTION_DISABLED &&
      !subscription.isActive
    ) {
      setItems(getClientMenuItems('disabled'));
    } else if (userDetails.role === USER_ROLE.ENTERPRISE) {
      setItems(getClientMenuItems('user'));
    } else {
      setItems(getClientMenuItems('basic'));
    }
  };

  useEffect(() => {
    if (userDetails.type === USER_TYPE.ADMIN) {
      setItems(getAdminMenuItems());
    } else if (userDetails.type === USER_TYPE.VENDOR) {
      setItems(getVendorMenuItems());
    } else {
      setClientMenuItems();
    }
  }, [subscription, userDetails]);

  const getAllowedMenuItems = () => {
    return [...menuItems.map((item) => item?.key)] as string[];
  };

  const getMainMenuItems = (): MenuItemProps[] => [
    {
      key: 'platfrom',
      text: t('header.menu.the_platform'),
      href: `${getLangRoute()}/`,
      children: [
        {
          key: 'faq',
          text: t('header.menu.faq'),
          href: `${getLangRoute()}/faq`,
        },
      ],
    },
    {
      key: 'company',
      text: t('header.menu.the_company'),
      children: [
        { key: 'about-us', text: t('header.menu.about_us'), href: `${getLangRoute()}/about-us` },
        { key: 'our-team', text: t('header.menu.our_team'), href: `${getLangRoute()}/our-team` },
      ],
    },
    { key: 'pricing', text: t('header.menu.pricing'), href: `${getLangRoute()}/pricing` },
    { key: 'vendor', text: t('header.menu.vendor'), href: `${getLangRoute()}/vendor` },
  ];

  const getFooterMenuItems = (): MenuItemProps[] => [
    getMainMenuItems()[0],
    getMainMenuItems()[1],
    {
      key: 'packages',
      text: t('header.menu.packages'),
      children: [
        { key: 'pricing', text: t('header.menu.pricing'), href: `${getLangRoute()}/pricing` },
      ],
    },
  ];

  const getMenuItems = (): MenuProps['items'] => {
    const items: MenuProps['items'] = [];
    getMainMenuItems().forEach((mi) => {
      let label;
      const children: MenuProps['items'] = mi.children ? [] : undefined;
      if (mi.children) {
        label = (
          <>
            <PinkGradientLink text={mi.text} excludeEl={true} />
            <ArrowDown />
          </>
        );
        mi.children.forEach((child) => {
          children?.push({
            key: child.key,
            label: <U1Link href={child.href} text={child.text} excludeEl={true} />,
            className: 'main-menu-item',
          });
        });
      } else {
        label = <PinkGradientRouterLink href={mi.href} text={mi.text} excludeEl={true} />;
      }
      const defaultProps = { key: mi.key, label };
      const finalProps = children ? { ...defaultProps, children } : defaultProps;
      items.push(finalProps);
    });
    return items;
  };

  const getAdminMenuItems = () => {
    return getFinalItems(adminMenu());
  };

  const getVendorMenuItems = () => {
    return getFinalItems(vendorMenu());
  };

  return {
    menuItems,
    getAllowedMenuItems,
    getMainMenuItems,
    getFooterMenuItems,
    clientMenu,
    adminMenu,
    vendorMenu,
    getMenuItems,
    getClientMenuItems,
    getAdminMenuItems,
    getVendorMenuItems,
  };
};
