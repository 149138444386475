import { packagesBG } from '@/assets/images/backgrounds';
import { CardProps } from '@/shared/props';
import styled from 'styled-components';
import { useThemeStore } from '@/stores/ThemeStore';
import { isMobile } from 'react-device-detect';

export const StyledTitle = styled.h3`
  color: ${(props) => props.theme.textColor};
  font-size: 24px;
  font-weight: 600;
`;

export const StyledPackagesCard = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${(props) => !props.$custom && `gap: 140px;`};
  flex: 30%;
  padding: 24px;

  min-height: 650px;
  ${() => !isMobile && `min-width: 380px;`}
  max-width: 380px;
  ${(props) =>
    useThemeStore.getState().isDarkMode
      ? `
      background-image: url('${packagesBG}');
      background-repeat: no-repeat;
      background-position-x: right;
    `
      : `background-color: ${props.theme.cardsColor};`}

  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 5px;
`;

export const StyledBilling = styled.span`
  color: ${(props) => props.theme.secondaryTextColor};
  font-weight: 400;
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 4px;
`;

export const StyledFreeTrial = styled.div`
  background-color: ${(props) => props.theme.inputColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 8px;
  width: 100%;
  height: 37px;
  border-radius: 46px;
  margin: 20px 0px 20px 0px;

  h4,
  span {
    font-weight: 700;
    background: ${(props) => props.theme.textGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 14px;
    margin-bottom: 0px;
  }
`;

export const StyledCardDetails = styled.div`
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  width: 100%;
  height: 100%;
`;

export const StyledDetailsList = styled.ul`
  color: ${(props) => props.theme.textColor};
  list-style-type: disc;
  padding-left: 17px;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 27px;

  li {
    margin-bottom: 8px;
  }

  li::marker {
    font-size: 0.5rem;
    font-weight: 200;
  }
`;

export const StyledFrameWorks = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledRowDetails = styled.li`
  display: flex;
  flex-direction: row;
  font-weight: 350;
  gap: 5px;
`;

export const StyledTopCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledButton = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 5px;
`;

export const StyledIcons = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0;
`;

export const StyledDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: space-between;
`;

export const StyledCustomTopCard = styled(StyledTopCard)`
  width: 100%;
`;
