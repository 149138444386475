import { StyledOrangeGradTitle } from '@/shared/styles';
import {
  StyledMainSectionsText,
  StyledSectionContainer,
  StyledTopDescription,
  StyledTrustedCompanies,
} from '../../MainPage.styles';
import { trustedCompaneis } from './TrustedBy.consts';
import { Icon } from '@/shared/components/Icon';
import { useThemeStore } from '@/stores/ThemeStore';
import { useTranslation } from 'react-i18next';

export const U1TrustedBy: React.FC = () => {
  const isDarkMode = useThemeStore((state) => state.isDarkMode);
  const { t } = useTranslation();
  return (
    <StyledSectionContainer>
      <StyledTopDescription>
        <StyledOrangeGradTitle level={2}>{t('homepage.trusted.title')}</StyledOrangeGradTitle>
        <StyledMainSectionsText>{t('homepage.trusted.content')}</StyledMainSectionsText>
      </StyledTopDescription>
      <StyledTrustedCompanies>
        {trustedCompaneis.map((comp) => (
          <Icon
            key={comp.src}
            $src={comp.src}
            $alt={comp.alt}
            $size={100}
            $loading="lazy"
            $invert={!isDarkMode}
          />
        ))}
      </StyledTrustedCompanies>
    </StyledSectionContainer>
  );
};
