import { DEALS_VIEW } from '@/Views/AfterLogin/Main/Vendor/Panels/Deals/Deals.consts';
import { USER_TYPE } from '@/shared/consts';
import {
  AdminsDto,
  DealsDto,
  FrameworkConfigData,
  FrameworkDto,
  ProjectData,
  UserDto,
  VendorsDto,
} from '@/shared/dto';
import { CompDataObjectModal } from '@/Views/AfterLogin/Main/Client/Projects/projects.consts';
import { ExtrasDetails, PackagesProps } from '@/shared/pages/Pricing/Pricing.consts';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export enum StoreState {
  INITIATED,
  NOT_INITIATED,
}

export type ActiveFrameworks = {
  name: string;
  icon: string;
  title: string;
};

export type SubscriptionDetails = {
  id: string;
  name: string;
  price: number;
  pricingMethod: string;
  users: number;
  domains: number;
  frameworks: string[];
  additionalServices: boolean;
  expiryDate: Date;
  nextPaymentAmount: number;
  isActive?: boolean;
  autoPayment: boolean;
  isTrial: boolean;
  paymentMethodId: string;
  isFreemium: boolean;
  supportedFrameworks: ActiveFrameworks[];
  imageUrl: string;
  subscriptionStatus: SUB_STATUS;
  freemiumComponents: string[];
};

export interface SubscriptionStore extends SubscriptionDetails {
  setSubscription: (obj: SubscriptionDetails) => void;
  setIsActive: (active: boolean) => void;
  setAutoPayment: (isAuto: boolean) => void;
  setSubscriptionStatus: (status: SUB_STATUS) => void;
}

export type SelectionType = {
  id: string;
  name: string;
  price: number | string;
  pricingMethod: string;
  users: ExtrasDetails;
  domains: ExtrasDetails;
  extraDomains: number;
  frameworks: ExtrasDetails;
  supportedFrameworks: ActiveFrameworks[];
  type: string;
  imageUrl: string;
};

export interface SelectionStore extends SelectionType {
  selectPackage?: (obj: SelectionType) => void;
  setExtraDomains?: (extraDomains: number) => void;
  setPackageId?: (id: string) => void;
}

export type DiscountStore = {
  discountCode: string;
  codeApplied: boolean;
  codeError: boolean;
  discountMsg?: string;
  setDiscountCode: (value: string) => void;
  setCodeApplied: (value: boolean) => void;
  setCodeError: (value: boolean) => void;
  handleDiscont: (value: boolean, msg: string) => void;
  clearDiscount: () => void;
};

type TotalPrice = {
  package: number;
  extraFrameworks: number;
  extraDomains: number;
  additionalService: number;
  total: number;
};

export type FreeFramework = {
  key: number;
  value: string;
};

export type ExtraSelectionType = {
  frameworks: string[];
  users: number;
  domains: number;
  additionalService: boolean;
  discount?: { id: string; value: number };
  totalPrice: TotalPrice;
  freeFramework?: FreeFramework;
};

export interface ExtraSelectionStore extends ExtraSelectionType {
  setSelection?: (obj: ExtraSelectionStore) => void;
  setPackagePrice?: (price: number) => void;
  cleanSelection?: () => void;
}

export enum LOGIN_STATE {
  UNINITIATED,
  LOGGED_IN,
  LOGGED_OUT,
  SECURITY_ALERT,
}

export type AuthStore = {
  loginState: LOGIN_STATE;
  isLoggedIn: () => boolean;
  setLoginState: (value: LOGIN_STATE) => void;
};

export enum Payment_Status {
  NOT_PAYED,
  SUCCESS,
  FREE_CHECKOUT,
  FAILED,
  UPGRADED,
  UPDATED,
  CHANGE_CARD,
}

export type PaymentStore = {
  clientSecret: string;
  paymentStatus?: Payment_Status;
  setClientSecret: (secret: string) => void;
  removeClientSecret: () => void;
  setPaymentStatus: (status: Payment_Status) => void;
};

export type NewProjectStore = {
  projectDomain: string;
  projectFramework: string;
  projectName: string;
  setProject: (project: NewProjectStore) => void;
  setProjectName: (projectName: string) => void;
  setProjectDomain: (projectDomain: string) => void;
  setProjectFramework: (projectFramework: string) => void;
};

export type BasicProjectsData = ProjectData;

export type BasicProjectsDataStore = BasicProjectsData & {
  setProject?: (project: BasicProjectsDataStore) => void;
};

export type ProjectsArrStore = {
  projectsArr: BasicProjectsDataStore[];
  projectsState: StoreState;
  setProjectsArr: (arr: BasicProjectsDataStore[]) => void;
  setEmptyArr: () => void;
};

export type ComponentIcon = { name: string; default: string; selected: string };

export type DocsStore = {
  framework: string;
  docsObj: CompDataObjectModal[];
  selectedComponent: number;
  componentsTreeList: string[];
  filteredTreeList: string[];
  compTreeItems: ItemType[];
  projectId: string;
  selectedComponentDoc?: CompDataObjectModal;
  compIcons: Record<string, Record<string, string>>;
  docsLang: string;
  setDocsObj: (docsObj: CompDataObjectModal[]) => void;
  updateDocsObj: (objecKeys: string[], updatedPart: string | string[], index?: number) => void;
  setFramework: (framework: string) => void;
  setSelectedComponent: (selectedComponent: number) => void;
  setComponentsTreeList: (componentsTreeList: string[]) => void;
  setFilteredTreeList: (componentsTreeList: string[]) => void;
  setProjectId: (projectId: string) => void;
  setCompIcons: (docsObj: CompDataObjectModal[]) => void;
  addNewProps: (objkeys: string[]) => void;
  removeFromDocs: (objecKeys: string[], index: number) => void;
};

export type UserType = {
  type: USER_TYPE;
  id: string;
  phone: string;
  firstName: string;
  fullName: string;
  email: string;
  avatarUrl: string;
  accountType: string;
  country: string;
  companyName: string;
  role?: string;
};

export interface UserStore extends UserType {
  setUser: (obj: UserDto) => void;
  setAvatar: (avatarUrl: string) => void;
}

export type TokenData = {
  value: string;
  permissions: string[];
  lastValidatedAt: number;
};

export type DetailedProjectsData = BasicProjectsData & {
  registryUrl: string;
  token: TokenData;
  credentials: CredentialsArrData[];
};

export type CredentialsArrData = {
  type: string;
  accessType: string;
  token: TokenData;
  basic: {
    username: string;
    password: string;
    permissions: string[];
  };
};

export type DetailedProjectsDataStore = DetailedProjectsData & {
  setDetailedProject: (detailedProject: DetailedProjectsDataStore) => void;
};

export type PackagesStore = {
  packages: PackagesProps[];
  setPackages: (packages: PackagesProps[]) => void;
  getPricing: (name: string, method: string, extra: string) => number;
};

export enum SUB_STATUS {
  NOT_INITIATED,
  NO_SUBSCRIPTION,
  HAS_SUBSCRIPTION,
  SUBSCRIPTION_DISABLED,
}

export type FrameworkStore = FrameworkDto & {
  isLoading?: boolean;
  getFrameworksData: () => void;
};

export type FrameworkConfigDataStore = FrameworkConfigData & {
  setFrameworkConfigData: (frameworkConfigData: FrameworkConfigData) => void;
  updateConfigObj: (objecKeys: string[], updatedPart: string | string[], index?: number) => void;
};

type UserDetails = {
  key: string;
  id: string;
  userName: string;
  email: string;
};

export type AdminDetails = UserDetails;

export type VendorDetails = UserDetails & {
  companyName: string;
  phone: string;
  status: string;
  dealCount: string;
  dealSum: string;
};

export type AdminsStore = {
  admins: AdminDetails[];
  vendors: VendorDetails[];
  isLoading: boolean;
  totalAdmins: number;
  totalVendors: number;
  setAdmins: (admins: AdminsDto) => void;
  setVendors: (vendorsDto: VendorsDto) => void;
  getAdmins: (pageNumber?: number, adminName?: string) => AdminDetails[];
  getVendors: (pageNumber?: number, vendorName?: string) => VendorDetails[];
  getAdminByName: (name: string) => AdminDetails[] | null;
  getVendorByName: (name: string) => VendorDetails[] | null;
  setUser: (
    type: string,
    setUser: (users: any) => void,
    pageNumber?: number,
    name?: string
  ) => void;
};

export type DealsDetails = {
  key: string;
  id: string;
  idNumber: string;
  companyName: string;
  subscriptionType: string;
  period: string;
  price: string;
  admin: string;
  status: string;
};

export type VendorsStore = {
  deals: DealsDetails[];
  isLoading: boolean;
  totalDeals: number;
  dealsView: DEALS_VIEW;
  credentialsSent: boolean;
  sendCredentials: (id: string) => void;
  setDealsView: (view: DEALS_VIEW) => void;
  getDeals: (companyName?: string, pageNumber?: number) => DealsDetails[];
  setDeals: (deals: DealsDto) => void;
};

export type ThemeState = {
  isDarkMode: boolean;
  toggleTheme: () => void;
  getTheme: () => string;
};
