import { FrameProps } from '@/shared/props';
import { useThemeStore } from '@/stores/ThemeStore';
import styled from 'styled-components';

export const StyledSider = styled.aside<FrameProps>`
  background: ${(props) =>
    !useThemeStore.getState().isDarkMode
      ? props.theme.bgContrast
      : props.$isClient
      ? props.theme.marginsColor
      : props.theme.secondMarginsColor};
  min-width: 82px;
  width: fit-content;
  ${(props) =>
    !useThemeStore.getState().isDarkMode
      ? `border-right: 1px solid ${props.theme.marginsBorder}`
      : !props.$isClient && `border-right: 1px solid ${props.theme.marginsBorder}`};
`;
