import { ConfigListItemModel } from '@/Views/AfterLogin/Main/Client/Projects/projects.consts';
import { removeProtocolPrefixRegex } from '@/shared/regex';
import { DetailedProjectsDataStore } from '@/stores/Stores.consts';
import { npmRcFrameworks } from './ConfigurationPage.consts';
import { StyledBold } from '@/shared/styles';

export const generateAndroidCredentials = (
  credentialObject: ConfigListItemModel,
  detailedProjectsDataStore: DetailedProjectsDataStore
) => {
  let credentialsString = credentialObject.code.replace(
    'registryUrlVar',
    detailedProjectsDataStore.registryUrl
  );
  detailedProjectsDataStore.credentials.forEach((credential) => {
    if (credential.type === 'BASIC') {
      credentialsString = credentialsString
        .replace('usernameVar', credential.basic.username)
        .replace('passwordVar', credential.basic.password);
    }
    if (credential.type === 'TOKEN' && credential.accessType === 'RUNTIME') {
      credentialsString = credentialsString.replace('runtimeTokenVar', credential.token.value);
    }
  });
  return credentialsString;
};

export const handleDynamicVariables = (
  credentialObject: ConfigListItemModel,
  detailedProjectsDataStore: DetailedProjectsDataStore
) => {
  let codeStr;
  switch (credentialObject.name) {
    case 'credentials':
      codeStr = generateAndroidCredentials(credentialObject, detailedProjectsDataStore);
      break;
    case 'authorization_token':
      codeStr = credentialObject.code.replace(
        'authorizationTokenVar',
        detailedProjectsDataStore.token.value
      );
      break;
    case 'create_npmrc':
      codeStr = credentialObject.code
        .replace(/registryUrlVar/g, detailedProjectsDataStore.registryUrl)
        .replace(/protocolVar/g, import.meta.env.DEV ? 'http' : 'https');
      break;
    default:
      codeStr = (credentialObject.code ?? '') as string;
      break;
  }
  return codeStr.split('{{ }}').filter((value) => value.length > 0);
};

export const formatProjectRegistryUrl = (registryUrl: string) => {
  return registryUrl.replace(removeProtocolPrefixRegex, '');
};

export const shouldShowLineNumbers = (codeLength: number) => {
  return codeLength > 1;
};

export const selectCodeLanguage = (framework: string) => {
  return npmRcFrameworks.includes(framework) ? 'shellSession ' : 'html';
};

export const getNewLines = (text: string) => {
  return text.split('\n').map((line, i) => {
    const props = { key: `line${i}` };
    return line ? (
      <span {...props}>
        {editConfigText(line)}
        <br />
      </span>
    ) : (
      <br {...props} />
    );
  });
};

export const editConfigText = (text: string): JSX.Element[] => {
  const regex = /\*(.*?)\*/g;
  const boldWords = text.match(regex);
  const jsxElements: JSX.Element[] = [];
  boldWords?.forEach(() => {
    const segments = text.split(regex);
    jsxElements.push(
      <>
        {segments[0]} <StyledBold>{segments[1]}</StyledBold>
        {segments[2]}
      </>
    );
  });
  return jsxElements.length ? jsxElements : [<>{text}</>];
};
