import { Checkbox } from 'antd';
import styled from 'styled-components';
import { CheckboxGroupStyleProps } from './Checkbox.consts';
import { isMobile } from 'react-device-detect';

export const StyledCheckboxGroup = styled(Checkbox.Group)<CheckboxGroupStyleProps>`
  display: flex;
  flex-direction: ${(props) => (isMobile || props.$isVertical ? 'column' : 'row')};
  ${() => isMobile && 'gap: 32px'};
`;

export const StyleldLabelMargin = styled.div`
  margin-right: 15px;
  display: flex;
  flex-direction: row;
`;

export const StyledCheckboxWrapper = styled.div`
  span {
    color: ${(props) => props.theme.textColor};
    font-weight: 300;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${(props) => props.theme.buttonsBG};
  }

  .ant-checkbox-inner {
    border-radius: 4px;
    border-image: ${(props) => props.theme.buttonsBG} 1;
    border-style: solid;
    border-width: 2px;
    background: transparent;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  }

  .ant-checkbox-inner::before {
    border-radius: 4px;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${(props) => props.theme.textColor};
  }

  .ant-checkbox-disabled {
    opacity: 0.5;
  }
`;
