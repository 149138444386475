import i18n from '@/services/localization';
import { getDaysInCurrentMonth } from './utils';

export const PackageTypes = {
  CUSTOM: 'CUSTOM',
  MAIN: 'MAIN',
  FREE: 'FREE',
};

export enum SUBSCRIPTION_METHOD {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const getSubscriptionType = (): Record<string, string> => {
  return {
    MONTH: i18n.t('pricing.monthly'),
    YEAR: i18n.t('pricing.annually'),
  };
};

export const SUBSCRIPTION_TYPE2: Record<string, string> = {
  MONTH: 'Monthly',
  YEAR: 'Annual',
  ONETIME: 'Onetime',
  TWO_YEARS: '2 Years',
};

export const FRAMEWORKS: Record<string, string> = {
  REACT: 'React',
  VUE_JS: 'VueJS',
  VUEJS: 'VueJS',
  ANGULAR: 'Angular',
  KOTLIN: 'Kotlin',
  JAVASCRIPT: 'JavaScript',
  ANDROID_XML_SDK: 'Android/XML',
};

export const SIDE_NAVIGATION = {
  PROJECTS: 'projects',
  USERS: 'users',
  BILLING: 'billing',
  PRICING: 'pricing',
  ADMINS: 'admins',
  VENDORS: 'vendors',
  DEALS: 'deals',
  STATS: 'stats',
  SETTINGS: 'settings',
  DOCS: 'docs',
};

export type PagesDict = Record<string, Record<string, string>>;

export const PAGES: PagesDict = {
  BEFORE_LOGIN: {
    MAIN_PAGE: '/home',
    ADMIN_SIGN_IN: '/admin',
    VENDOR_SIGN_IN: '/vendor',
    SIGN_IN: '/sign-in',
    SIGN_IN_INVITE: '/sign-in/invite',
    PRICING: '/pricing',
    OUR_TEAM: '/our-team',
    FAQ: '/faq',
    ABOUT_US: '/about-us',
    PASSWORD: '/password',
    SECURITY_ALERT: '/security-alert',
  },
  AFTER_LOGIN: {
    PAYMENT: '/payment',
    PRICING: `/${SIDE_NAVIGATION.PRICING}`,
    CHECKOUT: `/${SIDE_NAVIGATION.PRICING}/checkout`,
    NEW_PROJECT: `/${SIDE_NAVIGATION.PROJECTS}/new-project`,
    USERS: `/${SIDE_NAVIGATION.USERS}`,
    BILLING: `/${SIDE_NAVIGATION.BILLING}`,
    PROJECTS: `/${SIDE_NAVIGATION.PROJECTS}`,
    UPGRADE: `/${SIDE_NAVIGATION.BILLING}/upgrade`,
    CONFIGURATION: `/${SIDE_NAVIGATION.PROJECTS}/:projectIdParam/configuration`,
    DOCS: `/${SIDE_NAVIGATION.PROJECTS}/:projectIdParam/docs`,
    ADMINS: `/${SIDE_NAVIGATION.ADMINS}`,
    VENDORS: `/${SIDE_NAVIGATION.VENDORS}`,
    DEALS: `/${SIDE_NAVIGATION.DEALS}`,
    STATS: `/${SIDE_NAVIGATION.STATS}`,
    SETTINGS: `/${SIDE_NAVIGATION.SETTINGS}`,
    ADMIN_DOCS: `/${SIDE_NAVIGATION.DOCS}`,
    ADMIN_DOCUMENTATION: `/${SIDE_NAVIGATION.DOCS}/:projectIdParam/documentation`,
    ADMIN_CONFIGURATION: `/${SIDE_NAVIGATION.DOCS}/:projectIdParam/configuration`,
  },
  SHARED: {
    DEFAULT: '/',
    REDIRECT: '/sso/:provider/redirect',
    TERMS_AND_CONDITIONS: `/terms-and-conditions`,
    PRIVACY_POLICY: `/privacy-policy`,
    ACCESSIBILITY_STATEMENT: `/accessibility-statement`,
    NOT_FOUND: '*',
  },
};

export const LINKS = {
  TERMS: '/terms-and-conditions',
};

export const COOKIES = {
  ACCESS_TOKEN: 'accessToken',
  REDIRECT_URL: 'urlToRedirect',
  THEME: 'theme',
};

export enum Account_Type {
  GOOGLE,
  MICROSOFT,
  BITBUCKET,
  GITHUB,
  LINEKDIN,
}

export enum USER_TYPE {
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  VENDOR = 'VENDOR',
}

export enum USER_ROLE {
  ADMIN = 'ADMIN',
  USER = 'USER',
  ENTERPRISE = 'ENTERPRISE',
}

export enum Modifications_Code {
  FRAMEWORKS = 'FRAMEWORKS',
  DOMAINS = 'DOMAINS',
  TRAINING = 'TRAINING_AND_AUDITING_20H',
  USERS = 'USERS',
  ACCESSIBILITY_COMPONENTS = 'ACCESSIBILITY_COMPONENTS',
  PAYMENT_INTERVAL = 'PAYMENT_INTERVAL',
  ADDITIONAL_AUDITING_SERVICE = 'ADDITIONAL_AUDITING_SERVICE',
}

export const STORAGE_KEYS = {
  SELECTED_PACKAGE: 'selectedPackage',
};

export const LOCALES = {
  DAY_MONTH_YEAR: 'en-GB',
  MONTH_DAY_YEAR: 'default',
  EN_US: 'en-US',
};

export const NUM_OF_DAYS: Record<string, number> = {
  ONE_DAY: 1,
  TWO_WEEKS: 14,
  YEAR: 365,
  MONTH: getDaysInCurrentMonth(),
};

export const TIME: Record<string, number> = {
  DAYS_IN_WEEK: 7,
  HOURS_IN_DAY: 24,
  MINUTES_IN_HOUR: 60,
  MILLI_IN_SECOND: 1000,
};

export const RES_STATUS = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  UNPROCESSABLE: 422,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  CONTENT_TOO_LARGE: 413,
};

export enum FRAMEWORKS_ENUM {
  REACT = 'REACT',
  VUEJS = 'VUEJS',
  ANGULAR = 'ANGULAR',
  KOTLIN = 'KOTLIN',
  JAVASCRIPT = 'JAVASCRIPT',
  ANDROID_XML_SDK = 'ANDROID_XML_SDK',
  FLUTTER_SDK = 'FLUTTER_SDK',
}

export const FrameworkName: Record<string, string> = {
  REACT: 'ReactJS',
  VUEJS: 'VueJS',
  ANGULAR: 'Angular',
  JAVASCRIPT: 'JavaScript',
  ANDROID_XML_SDK: 'Android XML SDK',
  FLUTTER_SDK: 'Flutter',
};
